import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"

const FAQ = () => (
   <SubpageLayout>
      <Seo title="FAQ" />
      <header className="subpage--content-header">
        <h1>FAQ</h1>
      </header>

      <section>

        <p>Intercept: built by Richland Library and Aten Design Group, funded by the Knight Foundation.</p>
        <p>Intercept is an open source event management, room &amp; equipment reservations and customer tracking software developed by libraries, for libraries. Whether integrated with your library’s website or used as a stand-alone version, Intercept replaces your current calendaring and room reserve systems AND adds in the benefit of scanning customers at programs and events. This additional data point enables Intercept to make customized recommendations on programs, based on the customer’s attendance history.</p>

        <h2>What is Intercept?</h2>
        <ul>
          <li>A calendaring and events application</li>
          <li>A room reservation tool</li>
          <li>A customer engagement tool – scans customer library cards at programs, ties attendance to ILS account, makes recommendations</li>
          <li>Feedback mechanism for programming</li>
          <li>Open Source – available to anyone</li>
          <li>Optional Stand-alone version – small hosting fee</li>
        </ul>

        <h2>What are the benefits?</h2>
        <ul>
          <li>Open source means free – you need developers to install or money to pay a vendor, but no fee to any software company</li>
          <li>Open source means collaborative – see something that could be improved? Make a suggestion to the community and find someone to collaborate. Or pay a developer to make the enhancement. Either way, we all benefit. Then commit the code. No more waiting in a queue for enhancement.</li>
          <li>Tracking customers at programs results in ‘more of what they love.’ Patrons receive a customized experience, like Netflix or Amazon.</li>
          <li>Libraries have a tool for feedback</li>
        </ul>

        <h2>How do I get it?</h2>
        <ul>
          <li>Start by reading through <a href="https://www.drupal.org/docs/8/modules/intercept/installation-guide">the installation guide</a>.</li>
          <li>Have developers at hand? Download the code from <a href="https://www.drupal.org/project/intercept">Drupal.org</a> and get started. Instructions and documentation are available.</li>
          <li>Need a smaller approach? Use the stand-alone version for a small hosting fee at Pantheon.</li>

          <li></li>
        </ul>

        <h2>What about patron privacy?</h2>
        <ul>
          <li>Intercept collects one more piece of data about patrons, which is just as secure and protected as all the other types of data we collect on our customers. And scanning in at programs is totally voluntary.</li>
        </ul>

        <h2>Tell me about Room Reserve</h2>
        <p>Customers (using their library card) can go online, search for a study room, meeting room or larger room and reserve, once agreeing to the meeting room policy.</p>

        <h2>How do staff know about the day’s reservations?</h2>
        <p>Staff go online to their staff dashboard to see reservations and filter by location. We are working on the ability to print the day’s calendar.</p>

        <h2>Do you have to have a library card to reserve a room?</h2>
        <p>Yes, as the software is currently configured. Another library could configure the software to accept a username. Richland Library staff assist customers without a card.</p>

        <h2>Is room payment/charge integrated into Intercept?</h2>
        <p>Not currently, but this would be a great opportunity for another library to add on.</p>

        <h2>What ILS do you have to have?</h2>
        <p>Intercept was initially currently configured for Polaris. We have decoupled the ILS and the code is now ready for someone to connect another ILS.</p>

        <h2>Tell me about the Calendar</h2>
        <p>Staff log in to create programs, simultaneously reserving the room for the program. Customers view the programs on the website and sign up if registration is required. Once the program begins, staff click on a special tab and scan customers’ library cards. After the program, staff click on another tab to enter any notes and overall attendance numbers.</p>

        <h2>Can you push events up to MeetUp or another similar service?</h2>
        <p>Not currently, but this would be a great feature for another library to add.</p>

        <h2>System Requirements:</h2>
        <ul>
          <li>Modern Web Browser or Internet Explorer 11</li>
          <li>MySQL 5.5.3/MariaDB 5.5.20/Percona Server 5.5.8 + with InnoDB as the primary storage engine</li>
          <li>PHP version 7.1+ with PDO, XML, JSON, cURL, GD library and Mbstring extensions</li>
          <li>64MB minimum PHP memory requirement, 256MB recommended</li>
          <li>Web server read and write access for /sites/default/files folder</li>
          <li>Apache mod_rewrite/Nginx ngx_http_rewrite_module or web server equivalent for clean urls</li>
        </ul>

        <h2>Getting Started is as Easy as 123!</h2>

        <h3>1. Add Locations, Equipment and Event Types</h3>
        <p>You’ll be guided step-by-step through setting up locations and rooms, events, room reservations and equipment. The interface is easy for you to understand, so you can get your rooms and events set up quickly without a steep learning curve.</p>

        <h3>2. Make It Your Own</h3>
        <p>Intercept is ready to use as soon as you add your locations, equipment and events. Check out an example of the default theme you'll get right out of the box. Add your library's logo to let your customers know they’ve come to the right place. Take it a step further by customizing the colors, fonts and other design elements with help from your in-house developer or an agency such as Aten Design Group.</p>

        <h3>3. Train Your Staff</h3>
        <p>Each staff member is assigned a role so they only see what they need to see. This keeps customer data secure and prevents your staff from feeling overwhelmed. Check out our user roles and permissions tables to see what role is right for each of your staff members.<br /></p>
        <p>Once you determine which permissions your staff members individually need, you can get them enrolled in the Intercept system.</p>

        <hr />

        <h2>Have a question that wasn't covered?</h2>
        <h3>Please get in touch!</h3>
        <p><a href="mailto:intercept@richlandlibrary.com">intercept@richlandlibrary.com</a></p>

      </section>
   </SubpageLayout>

)

export default FAQ